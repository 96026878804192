import React, {useEffect, useState} from "react";
// import {render} from "react-dom";
import c from "./Item.module.css"
// import logo from "./../../imgs/logo.png"
// import cart from "./../../imgs/cart.png"
// import item1 from "./../../imgs/items/item1.jpg"
// import item2 from "./../../imgs/items/item2.jpg"
// import item3 from "./../../imgs/items/item3.jpg"
// import item4 from "./../../imgs/items/item4.jpg"
// import item5 from "./../../imgs/items/item5.jpg"
// import {NavLink} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {getCatListMain, getItemListMain} from "../../redux/index_reducer";
// import {CheckBox, createMyField} from "../common/FormsControls/FormsControls";
// import {reduxForm} from "redux-form";
import Preloader from "../Preloader/Preloader";
import {Button, Carousel, Image, Layout} from "antd";
// import Meta from "antd/lib/card/Meta";
// import cart from "../../imgs/cart.png";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {getCart, setCartOpened, updCart} from "../../redux/cart_reducer";
import {getItem, itemLoaded} from "../../redux/item_reducer";
import {
    checkIfItemIsInCart,
    cleanUndefinedItems,
    cleanUndefinedItemsIDs,
    getTotal,
    parseCartItemsIds
} from "../common/functions";
import {NavLink} from "react-router-dom";
import FavouritesModule from "../FavouritesModule/FavouritesModule";

const {  Content } = Layout;

const Item = (props) => {

    const {t} = useTranslation();
    let catsCheckedArr = [];
    let availableCount = 0;
    const [catsChecked,setCatsChecked] = useState(catsCheckedArr);
    const [isInCart,setIIC] = useState(false);

    const OnClick = (e) => {
        // console.log('e')
        // console.log(e)
    }

    useEffect(()=>{
        if(props.match.params.id && props.match.params.id !== ''){
            // console.log('item loaded')
            props.itemLoaded(props.match.params.id)
        }

    },[])

    useEffect(()=>{
        if(props.cats === null){
            props.getCatListMain()
        }
        // if(props.item === null){
            props.getItem(props.match.params.id)
        // }

    },[props.match.params.id])

    if(props.match.params.id && props.match.params.id !== ''){
        let itemArr = '';
        let photos = [];

        if(props.item !== null){
            // itemArr = props.items.filter((i)=> {
            //     return i.id === props.match.params.id ;
            // })
            itemArr = props.item
            photos = itemArr.photo.split(',')
            availableCount = parseInt(props.item.count) - parseInt(props.item.booked);
        }else{
            return <Preloader comment='loading 5'/>
        }


        const contentStyle = {
            height: '300px',
            color: '#fff',
            lineHeight: '300px',
            textAlign: 'center',
            background: '#364d79',
        };

        const getItemCats = (cats,catsMain,lang) => {
            let catArr = cats.split(',');
            let catList = [];
            catsMain.map((cat)=>{
                // console.log(parseInt(cat.id))
                catArr.map((ca)=>{
                    // console.log(parseInt(ca))
                    if(parseInt(ca) === parseInt(cat.id)){
                        // catList += '<a hreh="/category/'+ cat.id +'">'+(lang === 'ua' ? cat.name : cat.name_ru)+'</a> '
                        catList.push({
                            id : cat.id,
                            text : (lang === 'ua' ? cat.name : cat.name_ru)
                        })
                    }
                })
            })
            // console.log(catList)
            return catList;
        }

        const getAgeString = (age) => {
            // ages = [];
            let out = '';
            let ages = age.split('_');
            // console.log(ages)
            ages[0] = parseInt(ages[0])
            ages[1] = parseInt(ages[1])
            if(ages[0] !== 0){
                if(ages[0] === 1){
                    out += ages[0]+t('item.year1')
                }
                if(ages[0] > 1 && ages[0] < 5){
                    out += ages[0]+t('item.year4')
                }
                if(ages[0] > 4 && ages[0] < 21){
                    out += ages[0]+t('item.year10')
                }
            }
            if(ages[1] !== 0){
                if(out !== '' && ages[1] > 0){
                    out += ', '
                }
                if(ages[1] === 1){
                    out += ages[1]+t('item.month1')
                }
                if(ages[1] > 1 && ages[1] < 5){
                    out += ages[1]+t('item.month4')
                }
                if(ages[1] > 4 && ages[1] < 21){
                    out += ages[1]+t('item.month10')
                }
            }

            if(ages[0] === 0 && ages[1] === 0){
                out = t('item.for_babies')
            }

            return out;
        }





        const addToCart = async () => {
            // console.log(props.match.params.id)

            let cartItemsArr = (props.cart !== null && props.cart.items !== ''
                ? parseCartItemsIds(props.cart.items)
                : []);
            // console.log('cartItemsArr')
            // console.log(cartItemsArr)

            if(checkIfItemIsInCart(props.match.params.id,cartItemsArr)){
                // просто відкриваємо кошик
                props.setCartOpened(true)
                // console.log('open cart')
            }else{
                // дописуємо новий товар
                let items = cleanUndefinedItems(props.cart.items);
                let itemsIDs = cleanUndefinedItemsIDs(props.cart.items_ids);
                items = (props.cart !== null && items !== '' ? items + '||' : ''  ) + props.match.params.id+'|'+itemArr.name+'|'+itemArr.price+'|1';

                let cif = props.cartItemsFull;
                cif.push(props.item);

                // console.log('cif')
                // console.log(cif)
                // console.log('getTotal(items)')
                // console.log(getTotal(items))
                await props.updCart({
                        items: items,

                        total_price: getTotal(items,cif),//(props.cart !== null && props.cart.total_price !== '' ? parseInt(props.cart.total_price)  : 0  ) + parseInt(itemArr.price),

                        items_ids: (props.cart !== null && itemsIDs !== '' ? itemsIDs + ',' : ''  ) + props.match.params.id,
                    }
                )

                await props.getCart()
                // console.log('add to cart')
            }

        }

        return ( (props.cats === null || props.item === null || props.item.id !== props.match.params.id) ? <Preloader comment='loading 6'/> :
                <div className={c.mainpart}>

                    <div className={c.main_container}>

                        <Content>
                            <div className={c.name_container}>
                                <div className={c.name}>
                                    <h1>{i18n.language === 'ua' ? itemArr.name : itemArr.name_ru }</h1>
                                </div>
                                <div className={c.code}>
                                    {t('item.item_code')}: {itemArr.id}
                                </div>
                            </div>

                            <div className={c.item_info_container}>
                                <div className={c.gallery}>
                                    {photos.length > 0 ? <Carousel autoplay>
                                        {photos.map((ph)=> {
                                            return <div key={ph}>
                                                <div style={contentStyle}>
                                                    {/*<img src={"https://sweetkids.site/imgs/"+ph} className={c.photo}/>*/}
                                                    <Image
                                                        // width={200}
                                                        src={"https://sweetkids.site/imgs/"+ph}
                                                    />
                                                </div>
                                            </div>
                                        })}

                                    </Carousel> : <>no photo</>}

                                </div>

                                <div className={c.item_descr}>
                                    {itemArr.price
                                        ? <>
                                            {/*<span className={c.parameter}>Вартість:</span>*/}

                                            <div className={c.prices_container}>
                                                <div className={c.prices_sub_container}>
                                                    {(itemArr.old_price_active === '1' && itemArr.old_price > 0)
                                                        ? <div className={c.old_price} title={t('item.discount') + ' -' + itemArr.discount_percent + ' % (-' + itemArr.discount_money + ' ₴)'}>
                                                        {itemArr.old_price} ₴
                                                    </div>
                                                        : <div>&nbsp;</div>}
                                                    <div className={c.price + ' ' + ((itemArr.old_price_active === '1' && itemArr.old_price > 0)
                                                        ? c.price_discount
                                                        : null) } >
                                                        {itemArr.price} <span className={c.currency} title={'UAH - '+t('item.uah')}>₴</span>
                                                    </div>
                                                </div>


                                                <div className={c.buyButton}>
                                                    {itemArr.count > 0 ?
                                                    <Button type="primary" onClick={addToCart} disabled={availableCount <= 0 || (props.order.payingOrder !== null && props.order.payingOrder.status === 'paying') ? 'disabled' : ''}
                                                    title={availableCount <= 0
                                                        ? t('item.nothing_left')
                                                        : (props.order.payingOrder !== null && props.order.payingOrder.status === 'paying')
                                                            ? 'У вас є інше неоплачене замовлення' : ''}>
                                                        {/*<img src={cart} alt=""/> */}
                                                        {t('item.buy')}
                                                    </Button>
                                                    : <div className={c.no_items}>{t("item.no_items")}</div>}
                                                </div>
                                                <div className={c.favs}>
                                                    <FavouritesModule id={props.match.params.id} />
                                                </div>



                                                {/*<Button >У Кошик</Button>*/}
                                            </div>

                                            <br/></>
                                        : ''}

                                    {itemArr.size ? <><span className={c.parameter}>{t('item.size')}:</span> {itemArr.size}<br/></> : ''}
                                    {(itemArr.age && itemArr.age !== '' )
                                        ? <><span className={c.parameter}>{t('item.age')}:</span> {getAgeString(itemArr.age)}<br/></>
                                        : ''}
                                    {itemArr.height
                                        ? <><span className={c.parameter}>{t('item.height')}:</span> {itemArr.height} см.<br/></>
                                        : ''}
                                    {availableCount > 0
                                        ? <><span className={c.parameter}>{t('item.count')}:</span> {availableCount} шт.<br/></>
                                        : <><span className={c.parameter}>{t('item.count')}:</span> {t('item.nothing_left')}<br/></>}
                                    {(i18n.language === 'ua' ? itemArr.descr : itemArr.descr_ru)
                                        ? <fieldset><legend className={c.parameter}>{t('item.details')}:</legend> {i18n.language === 'ua' ? itemArr.descr : itemArr.descr_ru} <br/></fieldset>
                                        : ''}
                                    {itemArr.categories
                                        ? <><span className={c.parameter}>{t('item.categories')}:</span> {getItemCats(itemArr.categories,props.cats,i18n.language).map((cat)=>{
                                            return <span key={cat.id}><NavLink to={'/category/'+cat.id}>{cat.text}</NavLink> </span>
                                        })} <br/></>
                                        : ''}

                                    {/*{itemArr.discount_money*/}
                                    {/*    ? <><span className={c.parameter}>Знижка:</span> {itemArr.discount_money} <br/></>*/}
                                    {/*    : ''}*/}


                                    {/*<br/>*/}
                                    {/*<br/>*/}
                                    {/*<a href='https://www.instagram.com/__sweet.kids/'><div>{t('item.temp_text')}</div></a>*/}
                                </div>
                            </div>


                        </Content>


                    </div>



                </div>
        )
    }else{
        return <div className={c.mainpart}>

            <div className={c.main_container}>

                <Content>
                    <div>
                        Такого товару не існує, спробуйте обрати інший
                    </div>

                </Content>


            </div>




        </div>
    }

}

const mstp = (state) => {
    return {
        cats: state.index.catList,
        item: state.item.item,
        cart: state.cart.cart,
        order: state.order,
        cartItemsFull: state.cart.cartItemsFull
    }

}

export default compose(connect(mstp,{getItemListMain,getCatListMain,updCart,getCart,setCartOpened,getItem,getTotal,itemLoaded}))(Item);