import React, {useEffect, useState} from "react";
import c from "./MyOrders.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel, getItemsListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {delItem, getCatList, getItemList, saveItem} from "../../redux/admin_reducer";
import Preloader from "../Preloader/Preloader";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import AdminItemList from "../AdminItemList/AdminItemList";
import {compose} from "redux";
import {CheckBox, createMyField, Hidden, Input, Textarea} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Info from "../common/Info/Info";
import {required} from "../../utils/validate/validator";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {getMyOrders} from "../../redux/user_reducer";
import {Table, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {Header} from "antd/lib/layout/layout";
import {createMarkup, getColorByStatus} from "../common/functions";



// https://docs.dndkit.com/presets/sortable





const MyOrders = (props) => {

    const { t } = useTranslation();
    useEffect(()=>{
        if(props.myOrders === null){
            // console.log('test 1')
            props.getMyOrders()
        }else{
            // console.log('test 2')
            // console.log(props.myOrders)
        }


    },[])
    if(props.myOrders !== null){
        // console.log(props.myOrders)
    }

    function parseDate(number) {
        let d = new Date(+number * 1000)
        function pad(s) { return (s < 10) ? '0' + s : s; }
        // var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/') + ' '
            + [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':')
    }

    let dataSource = Array()
    if(props.myOrders !== null){
        // let dataSource = Array()
            props.myOrders.map((o)=>{
                dataSource.push( {
                    id : o.id,
                update_date : parseDate(o.update_date),
                amount : o.total_amount + ' ' + t('pay.valuta'),
                status : o.status,
                    key : o.id
        })

                // return <><NavLink to={'/pay/'+o.id}>{o.id+' '+o.update_date +' '+o.total_amount +' '+o.status}</NavLink><br/></>
            })
        // console.log(dataSource)
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <NavLink to={'/view_order/'+record.id}>
                    {record.id}
                </NavLink>
            )
        },
        // {
        //     title: t('myOrders.update_date'),
        //     dataIndex: 'update_date',
        //     key: 'update_date',
        // },
        {
            title: t('myOrders.amount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                <NavLink to={'/view_order/'+record.id}>
                    {record.amount}
                </NavLink>
            )
        },
        {
            title: t('myOrders.status'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <NavLink to={'/view_order/'+record.id}>
                    <Tag color={getColorByStatus(record.status)} key={record.status}>
                        <div dangerouslySetInnerHTML={createMarkup(t('order_status.'+record.status).toUpperCase())}></div>

                    </Tag>


                </NavLink>
            ),
        },
        // {
        //     title: t('myOrders.actions'),
        //     key: 'actions',
        //     render: (text, record) => (
        //             <NavLink to={'/view_order/'+record.id}>{t('myOrders.view_order')}</NavLink>
        //     ),
        // },
    ];



    return (props.myOrders === null ? <Preloader/> : <>

        {props.auth.isAuth && props.auth.login !== 'no_login'
            ? <div className={c.main_container}>
                <div className={c.header}>{t('myOrders.title')}</div>
                <>
                    {/*ID DATE AMOUNT STATUS<br/>*/}

                    <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 20, hideOnSinglePage:true }}
                           size="small" />
                </>

            </div>
            : <div dangerouslySetInnerHTML={createMarkup(t('cabinet.not_authorized'))}></div>
        }
    </>)
}

const mstp = (state,props) => {



    return {
        auth: state.auth,
        myOrders: state.user.myOrders
    }
}

export default compose(withRouter,connect(mstp,{getItemList,saveItem,getCatList,getMyOrders}))(MyOrders);