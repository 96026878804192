import React from "react";
import {render} from "react-dom";
import c from "./Footer.module.css"
import logo from "./../../imgs/logo.png"
import cart from "./../../imgs/cart.png"
import {NavLink} from "react-router-dom";

const Footer = () => {

    return (
        <div className={c.footer}>
            ** 2021 **




        </div>
    )
}

export default Footer;