import React, {useEffect, useState} from "react";
// import {render} from "react-dom";
import c from "./CartPreview.module.css"
// import logo from "./../../imgs/logo.png"
// import cart from "./../../imgs/cart.png"
// import item1 from "./../../imgs/items/item1.jpg"
// import item2 from "./../../imgs/items/item2.jpg"
// import item3 from "./../../imgs/items/item3.jpg"
// import item4 from "./../../imgs/items/item4.jpg"
// import item5 from "./../../imgs/items/item5.jpg"
// import {NavLink} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {getCatListMain, getItemListMain} from "../../redux/index_reducer";
import {CheckBox, createMyField} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Preloader from "../Preloader/Preloader";
import {Button, Card, Image, Input, InputNumber, Layout} from "antd";
import Meta from "antd/lib/card/Meta";
import {NavLink, Redirect} from "react-router-dom";
import {getCart, setCartItemsFull, setCartOpened, updCart} from "../../redux/cart_reducer";
import Icon, {
    DeleteFilled,
    DeleteOutlined, HeartFilled,
    HeartOutlined,
    HeartTwoTone,
    MinusOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {getTotal} from "../common/functions";
import FavouritesModule from "../FavouritesModule/FavouritesModule";

const { Sider, Content } = Layout;

// const FilterCatsForm = ({cats,catsChecked,setCatsChecked,getItemListMain}) => {
//
//     const combineCheckedCats = (e) => {
//         // setCatsChecked()
//         // console.log(e.target.getAttribute('cat_id'))
//
//         // console.log(catsChecked)
//         // console.log(parseInt(e.target.getAttribute('cat_id')))
//         // console.log((e.target.checked === true))
//
//         const sss = parseInt(e.target.getAttribute('cat_id'))
//         const ddd = (e.target.checked === true)
//         // console.log(sss)
//         // console.log(ddd)
//
//
//         catsChecked[sss] = ddd;
//         // console.log('catsChecked 1')
//         // console.log(catsChecked)
//         setCatsChecked(catsChecked)
//         // console.log('catsChecked 2')
//         // console.log(catsChecked.join())
//         getItemListMain(20,1,catsChecked.join());
//     }
//     return <form >
//
//         {cats.map((cat)=>{
//             return <div key={cat.id}>
//                 {cat.active === '1' && ( cat.name === '***** interval *****' ? <div>&nbsp;</div> :
//
//                         createMyField(null,CheckBox,'cats['+cat.id+']',[],{onClick:combineCheckedCats,cat_id:cat.id},cat.name)
//
//                         // <label><input type='checkbox' name={'cats['+cat.id+']'} /> {cat.name}</label>
//                 )
//                 }
//
//             </div>
//         })}
//     </form>
// }
//
// const FilterCatsFormRedux = reduxForm({
//     form: 'FilterCatsForm'
// })(FilterCatsForm);

const CartPreview = (props) => {

    // let catsCheckedArr = [];
    // const [catsChecked,setCatsChecked] = useState(catsCheckedArr);
    //
    // const OnClick = (e) => {
    //     // console.log('e')
    //     // console.log(e)
    // }
    //
    // useEffect(()=>{
    //     if(props.cats === null){
    //         props.getCatListMain()
    //     }
    //     if(props.items === null){
    //         props.getItemListMain()
    //     }
    // },[])


    const { t } = useTranslation();
    const [cartCount,setCartCount] = useState(0);
    // const [cartVisible,setCartVisible] = useState(false);
    // console.log(t)


    const [itemsCount,setItemsCount] = useState(0)
    const [itemsPrices,setItemsPrices] = useState(null)
    const [totalPrice,setTotalPrice] = useState(1) // for proper redirect functionality if cart is empty
    useEffect(()=>{

        const sIC = (arr) => {
            setItemsCount(arr)
            // console.log(arr)
        }
        let tempItemsCount = []
        let tempItemsPrices = []
        if(props.isAuth){
            if(props.cart !== null){
                parseCartItems(props.cart.items).map((item)=>{

                    tempItemsCount[item[0]] = item[3]
                    let curItemPrice = parseInt(item[2]) * parseInt(item[3])
                    tempItemsPrices[item[0]] = curItemPrice

                })
                sIC(tempItemsCount)
                setItemsPrices(tempItemsPrices)
                setTotalPrice(getTotal(props.cart.items,props.cartItemsFull))
                // settestval(tempItemsCount)
                // console.log('totalPrice1 - ' + totalPrice)
            }
            if(props.cart === null || itemsCount === null ){
                props.getCart()
            }

        }

        // console.log(itemsCount)
        // console.log(itemsCount.length)
    },[props.cart,props.cart !== null ? props.cart.total_price : true])

    const closeCart = () => {
        // setCartVisible(false)
        props.setCartOpened(false)
    }
    const openCart = () => {
        // setCartVisible(true)
        props.setCartOpened(true)
    }

    const parseCartItems = (itemsString) => {
        if(itemsString !== undefined && itemsString !== ''){
            let itemsArrTemp = itemsString.split('||');
            return itemsArrTemp.map((i)=>{
                return i.split('|')
            })
        }else{
            return []
        }

    }

    // const [testval,settestval] = useState([])


    const numberChanged = (val,id) => {
        // console.log('changed', val,id );
        let tempArr = itemsCount
        tempArr[id] = val
        // settestval(tempArr)
        setItemsCount(tempArr)

        // let sss = testval
        // console.log(sss[id])
        // sss[id] = val
        // console.log(sss[id])
        // settestval(sss)
        let tempItemsCount = itemsCount
        let tempItemsPrices = itemsPrices


        if(props.isAuth){
            let itemsArr = parseCartItems(props.cart.items).map((item)=>{
                if(item[0] === id){
                    item[3] = isNaN(parseInt(val)) ? 0 : parseInt(val)
                    tempItemsCount[id] = item[3]
                    tempItemsPrices[id] = parseInt(item[2]) * parseInt(item[3])
                    setItemsCount(tempItemsCount)
                    setItemsPrices(tempItemsPrices)
                    // console.log(itemsCount)
                }
                return item;
            });
            // updCart(newCartData)
            let itemsArr2 = itemsArr.map((i)=>{
                return i.join('|')
            })
            // console.log(itemsArr2.join('||'))

            let cart2 = props.cart;
            cart2.items = itemsArr2.join('||')
            let total = getTotal(props.cart.items,props.cartItemsFull)
            cart2.total_price = total
            props.updCart(cart2)
            setTotalPrice(total)
            // console.log('totalPrice2 - ' + totalPrice)
        }


    }

    // const [coun]
/*

    const [itemsArr,setItemsArr] = useState(null)

    const minusCount = (e) => {
        let co = e.target.parentNode.parentElement.parentElement.children.count;
        console.log(co.value);
        if(co.value > 0){
            co.value = co.value - 1
        }
    }
    const plusCount = (e) => {
        console.log(e)
        let tempItemsCount = itemsCount
        let itemsArr = parseCartItems(props.cart.items).map((item)=>{
            if(item[0] === e){
                item[3] = parseInt(item[3])+1
                tempItemsCount[e] = item[3]
                setItemsCount(tempItemsCount)
                console.log(itemsCount)
            }
            return item;
        });
        // updCart(newCartData)
        let itemsArr2 = itemsArr.map((i)=>{
            return i.join('|')
        })
        // console.log(itemsArr2.join('||'))

        let cart2 = props.cart;
        cart2.items = itemsArr2.join('||')
        props.updCart(cart2)
        /!*let co = e.target.parentNode.parentElement.parentElement.children['count'];
        console.log(e.target.parentNode.parentElement.parentElement.children['count']);
        console.log(e.target.parentNode.parentElement.parentElement.children.count);
        if(parseInt(co.value) < parseInt(co.getAttribute('maxval'))){
            co.value = parseInt(co.value) + 1
        }*!/
    }
*/

    // console.log(props.cart)
    // console.log(itemsCount.length)

    const HeartSvg = () => (
        <svg viewBox="64 64 896 896" focusable="false" data-icon="heart" width="1em" height="1em" fill="currentColor" aria-hidden="true">
            <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z" fill="#eb2f96"></path>
            <path d="M679.7 201c-73.1 0-136.5 40.8-167.7 100.4C480.8 241.8 417.4 201 344.3 201c-104 0-188.3 82.6-188.3 184.5 0 201.2 356 429.3 356 429.3s356-228.1 356-429.3C868 283.6 783.7 201 679.7 201z" fill="#eb2f96"></path>
        </svg>
    );

    const MyHeartIconFilled = props => <Icon component={HeartSvg} {...props} />;

    const delFromCart = (id) => {

        if(props.isAuth){
            let newCartItems = parseCartItems(props.cart.items).filter((i)=>{
                if(i[0] !== id){
                    return i;
                }
            }).map((i)=>{
                return i.join('|')
            })
            // console.log(itemsArr2.join('||'))

            let cart2 = props.cart;
            cart2.items = newCartItems.join('||')
            cart2.items_ids = cart2.items_ids.split(',').filter((i)=>{
                return i !== id
            }).join()

            let cart3 = props.cartItemsFull.filter((i)=>{
                return i.id !== id
            });
            props.setCartItemsFull(cart3)
            let total = getTotal(props.cart.items,props.cartItemsFull)
            cart2.total_price = total
            props.updCart(cart2)
            setTotalPrice(total)
            // console.log('totalPrice3 - ' + totalPrice)
        }
    }

    let cartSubmit = () => {
        props.setCartOpened(false)
    }

    return ( ((props.cart === null || itemsCount === null) && props.isAuth)
            ? <div>
                <Preloader  comment={'loading 3 - ' + props.cart + ' - ' + props.isAuth}/>
            </div>
            : <div >
                {/*<div className={c.cart_container_back_cover} onClick={closeCart}></div>*/}
                <div >
                    <div className={c.cart_header}>
                        <div className={c.cart_name}>{t('cart.cart_preview')}</div>
                        {/*<div className={c.cart_close} onClick={closeCart}>x</div>*/}
                    </div>
                    <div className={c.cart_body}>
                        {props.cart === null ? t('cart.empty') :

                            props.cartItemsFull !== null && props.cartItemsFull.map((i)=>{
                                let availableCount = parseInt(i.count) - parseInt(i.booked);

                                // console.log(itemsCount)
                                let photos = i.photo.split(',')
                            // console.log((itemsCount[i.id]))
                            if(itemsCount[i.id] === undefined){
                                return null;
                            }else{
                                return <div className={c.cart_item + ' ' + (availableCount <= 0 ? c.cart_item_out_of_stock : '')} key={i.id}>
                                    <div className={c.cart_image}>
                                        <Image
                                            // width={200}
                                            src={"https://sweetkids.site/imgs/"+photos[0]}
                                        />
                                    </div>
                                    <div className={c.cart_details}>
                                        <div className={c.cart_details1}>
                                            <div className={c.cart_item_name}>
                                                <NavLink to={'/item/'+i.id}>{i18n.language === 'ua' ? i.name : i.name_ru}</NavLink>
                                            </div>
                                            <FavouritesModule id={i.id} />


                                            <DeleteFilled className={c.delete} title={t("cart.del_title")} onClick={()=>{delFromCart(i.id)}}/>
                                        </div>
                                        <div className={c.cart_details2}>
                                            <div className={c.cart_count}>

                                                {/*<span className={c.plus_minus} onClick={()=>minusCount(i.id)}><MinusOutlined/></span>*/}

                                                {/*<Input name="count" onChange={numberChanged}  maxLength={25} maxval={i.count} value={itemsCount[i.id]}*/}
                                                {/*/>*/}
                                                {availableCount > 0 ?
                                                    <InputNumber min={1} defaultValue={itemsCount[i.id]} value={itemsCount[i.id]} max={availableCount}  onChange={(val)=>{numberChanged(val,i.id)}} />
                                                    : <>{t('cart.out_of_stock')}</>
                                                }

                                                {/*<span className={c.plus_minus} onClick={()=>plusCount(i.id)} ><PlusOutlined/></span>*/}

                                            </div>

                                            <div className={c.cart_price}>
                                                {itemsPrices[i.id]} UAH
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            }

                            })

                        }
                    </div>
                    <div className={c.cart_footer}>
                        {totalPrice > 0 || (totalPrice === 0 && props.cartItemsFull.length > 0) ?
                            <div>
                                <span className={c.total}>
                                {t('cart.total') + totalPrice} UAH
                                </span>
                                {/*<NavLink to={'/order'}>
                                    <Button className={c.cart_submit} onClick={cartSubmit}>
                                        {t('cart.submit')}
                                    </Button>
                                </NavLink>*/}

                            </div>
                            : <div className={c.empty_cart}>
                                {t('cart.cart_empty')}
                                <Redirect to='/index'/>

                            </div>
                        }

                    </div>
                </div>
            </div>
    )
}

const mstp = (state) => {
    return {
        cart: state.cart.cart,
        cartOpened: state.cart.cartOpened,
        cartItemsFull: state.cart.cartItemsFull,
        isAuth: state.auth.isAuth
        // items: state.index.itemList
    }

}

export default compose(connect(mstp,{getCart,setCartOpened,updCart,setCartItemsFull}))(CartPreview);