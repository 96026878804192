import React, {useEffect, useState} from "react";
import { reduxForm} from "redux-form";
import {connect} from "react-redux";
import {login, checkAuthorization, setCaptchaAnswer} from "../../redux/auth_reducer";
import {NavLink, Redirect} from "react-router-dom";
import {compose} from "redux";
import {createMyField, Hidden, } from "../common/FormsControls/FormsControls";
import {maxLenCreator, minLenCreator, required} from "../../utils/validate/validator";
import c from "./Order.module.css"
import {Button, DatePicker, Form, Checkbox, Row, Col, Radio} from "antd";
// import "antd/dist/antd.css";
import { Input, Tooltip } from 'antd';
import {
    EnvironmentOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    KeyOutlined,
    NumberOutlined, PhoneOutlined,
    UnlockOutlined,
    UserOutlined
} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import CartPreview from "../CartPreview/CartPreview";
import {getCart} from "../../redux/cart_reducer";
import {addOrder} from "../../redux/order_reducer";
import Info from "../common/Info/Info";
import Error from "../common/Error/Error";
import Preloader from "../Preloader/Preloader";

const OrderContainer = (props) => {
    return <Order {...props}/>
}

const Order = (props) => {

    const { t } = useTranslation();

    const [rememberMe,setRM] = useState(true);

    const onSubmit = ({email,pass}) => { //formData
        //
        if (props.auth.isAuth === false || props.auth.temp === '1') {
            props.login(email, pass, rememberMe)
        } else {
            // return <Redirect to={'/index'}/>
        }
        // console.log('rememberMe')
        // console.log(rememberMe)
    }
    // const onSubmit2 = ({email2,pass2,rememberMe2}) => { //formData
    //     //
    //     // console.log('email = ' + email2)
    //     if (props.auth.isAuth === false) {
    //         props.login(email2, pass2, rememberMe2)
    //     } else {
    //         return <Redirect to={'/index'}/>
    //     }
    //     // console.log(formData)
    // }
    return <div className={c.form_container}>
        <h1>{t('order.title')}</h1>
        <OrderReduxForm onSubmit={onSubmit} {...props}  /><br/>
        {/*{t('auth.if_no_login')} <NavLink to='/signup'>{t('auth.register')}</NavLink>*/}
    </div>
}
const maxLen50 = maxLenCreator(50);
const minLen2 = minLenCreator(2);
// const numOnly = onlyNumbers();

// main login_form
const OrderForm = ({auth, handleSubmit, error,setCaptchaAnswer,rememberMe,setRM,login,cart,getCart,addOrder,history}) => {

    // remember captcha in State for both accounts (to they both could use it while authorizing)
    // const formChanged = (e) => {
    //     if(e.target.name === 'captcha'){
    //         setCaptchaAnswer(e.target.value)
    //     }
    // }

    useEffect(()=>{
        if(cart.cart === null  ){
            getCart()
        }
        if(cart.cart !== null){
            if(payWay === 'full'){
                setPayNow(cart.cart.total_price)
            }
            if(payWay === 'part'){
                setPayNow(100)
            }


        }

    },[cart.cart,cart.cart !== null ? cart.cart.total_price : true])

    const { t } = useTranslation();
    const [loadingButton,setLB] = useState(false)
    const [error_noMoreItems,setError_noMoreItems] = useState(false)
    const [error_other,setError_other] = useState(false)

    const rmClick = (e) => {
        // console.log(e.target.checked);
        // setRM(e.target.checked);
    }

    const onFinish = async (values) => {


        // let rm = values['rememberMe'] === undefined ? false : values['rememberMe'];
        // console.log('Success:', values, deliveryWay,payWay,cart);
        let result = await addOrder({
            deliveryWay:deliveryWay,
            payWay:payWay,
            payDetails:values,
            orderDetails:cart.cart
        });

        // console.log(result)
        // console.log(result.error)
        if(result.error === 0 && result.main_data.order_id > 0)
            history.push('/pay/'+result.main_data.order_id)
        else{
            if (result.main_data.message === 'not enough items'){
                setError_noMoreItems(true);
                getCart();
            }else{
                setError_other(result.main_data.message);
            }

        }



        /*
                {
                    "city": "дом",
                    "department": "323",
                    "address": "адреса",
                    "phone": "456456456",
                    "name": "афанасій"
                }
                {
                    "cart": {
                    "id": "6",
                        "user_id": "1",
                        "items": "88|тест Disney|400|2||85|Рожева футболка Міні Маус - Cool Club|280|1||89|Тестовий Name it|700|9",
                        "items_ids": "88,85,89",
                        "total_price": 7380
                },
                    "cartOpened": false,
                    "cartItemsFull": [
                    {
                        "id": "85",
                        "active": "1",
                        "item_order": "0",
                        "name": "Рожева футболка Міні Маус - Cool Club",
                        "descr": "Рожева футболка Міні Маус - Cool Club",
                        "name_ru": "Розовая футболка Мини Маус - Cool Club",
                        "descr_ru": "Розовая футболка Мини Маус - Cool Club",
                        "count": "1",
                        "photo": "18cdf49b17f544d354d42804caf19474.jpg,872f03034556ee05af55ee95a2c90b99.jpg",
                        "categories": "4,38,55,59",
                        "price": "280",
                        "size": "",
                        "height": "",
                        "age": "0_0",
                        "date_added": "1624739423",
                        "date_edited": "1626642194",
                        "discount_money": "0",
                        "discount_percent": "0",
                        "old_price": "0",
                        "old_price_active": "1"
                    },
                    {
                        "id": "88",
                        "active": "1",
                        "item_order": "3",
                        "name": "тест Disney",
                        "descr": "опис",
                        "name_ru": "тест Disney",
                        "descr_ru": "описание",
                        "count": "2",
                        "photo": "d8d1da648105fe5072fa25de996abe2e.png",
                        "categories": "3,4,35,36",
                        "price": "400",
                        "size": "3,4",
                        "height": "122",
                        "age": "2_6",
                        "date_added": "1624779767",
                        "date_edited": "1628814152",
                        "discount_money": "223",
                        "discount_percent": "40.11",
                        "old_price": "556",
                        "old_price_active": "1"
                    },
                    {
                        "id": "89",
                        "active": "1",
                        "item_order": "0",
                        "name": "Тестовий Name it",
                        "descr": "тест укр",
                        "name_ru": "тестовый Name it",
                        "descr_ru": "тест ру",
                        "count": "50",
                        "photo": "d099a783607992f20945d564ea156ebb.png",
                        "categories": "3,4,35,37",
                        "price": "700",
                        "size": "15",
                        "height": "100",
                        "age": "3_0",
                        "date_added": "1624800439",
                        "date_edited": "1630617760",
                        "discount_money": "150",
                        "discount_percent": "17.65",
                        "old_price": "850",
                        "old_price_active": "1"
                    }
                ]
                }*/

        // if (auth.isAuth === false || auth.temp === '1') {
        //     login(values['email'], values['pass'], rm)
        //         .then(()=>{
        //             setLB(false)
        //         })
        // } else {
        //     return <Redirect to={'/index'}/>
        // }
        // console.log('save order, get order_id, redirect to payment page with order_id')
        // saveOrder()
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setLB(false)
        // setFormGeneralError(true)
        setInfoVisible()
    };


    const buttonClicked = () => {
        // setLB(true)
    }
    const style = { background: '#0092ff', padding: '8px 0' };

    const delivery_way = (e) => {
        // console.log('e')
        // console.log(e.target.value)
        setDeliveryWay(e.target.value)
    }
    const pay_way = (e) => {
        setPayWay(e.target.value)
        if(e.target.value === 'full'){

            setPayNow(cart.cart !== null ? cart.cart.total_price : 0)
        }
        if(e.target.value === 'part'){
            setPayNow(100)
        }

    }
    const [payNow, setPayNow] = useState(cart.cart !== null ? cart.cart.total_price : 0)

    const [deliveryWay,setDeliveryWay] = useState('np')
    const [payWay,setPayWay] = useState('full');
    const [formGeneralError,setFormGeneralError] = useState(false)

    const [tempInfoVisible,setTempInfoVisible] = useState(false)

    const setInfoVisible = () => {
        setTempInfoVisible(true)
        setTimeout(function(){
            setTempInfoVisible(false)
        },3000)
    }


    // if (auth.isAuth === false || auth.temp === '1') {
        return (cart.cart !== null
            ? <Row >
            <Col className="gutter-row"
                 xs={{ span: 24, offset: 0 }}
                 sm={{ span: 22, offset: 1 }}
                 md={{ span: 20, offset: 2 }}
                 lg={{ span: 18, offset: 3 }}
                 xl={{ span: 16, offset: 4 }}
                 xxl={{ span: 16, offset: 4 }}
            >
                {/*<div style={style}>col-6</div>*/}
                <Form onSubmit={handleSubmit}
                    // onChange={formChanged}

                      name="basic"
                      labelCol={{
                          // span: 8,
                      }}
                      // wrapperCol={{
                      //     span: 12,
                      //     offset: 6
                      // }}
                      initialValues={{
                          remember: true,
                          department: auth.np_dep,
                          address: auth.address,
                          city: auth.city,
                          name: auth.receiver,
                          index: auth.zip,
                          phone: auth.phone
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                >

                    {error ? <Form.Item>
                        <div className={(!error ? c.noError : '') + ' ' + c.error}>
                            {error}
                        </div>
                    </Form.Item> : ''}

                    <fieldset>

                        <div className={c.block_header}>{t('order.choose_delivery_way')}</div><br/>

                        <Form.Item>
                            <Radio.Group onChange={delivery_way} defaultValue={deliveryWay} >
                                <Radio.Button value="np">{t('order.np')}</Radio.Button>
                                <Radio.Button value="up">{t('order.up')}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>


                        {/*
                        адреса доставки (з індексом), або місто та номер відділення НП (тоді обов'язковий номер телефону та ПІП)
                    */}

                        {/*<br/>*/}

                        {deliveryWay === 'np'
                            ?
                            <div>

                                <Form.Item
                                    // label={t('order.city_place')}
                                    name="city"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.city_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<HomeOutlined/>}
                                        placeholder={t('order.city_place')}
                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="department"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.department_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<><NumberOutlined/> &nbsp; {t('order.department')}</>}
                                        placeholder={t('order.department_place')}

                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="address"
                                    rules={[
                                        {
                                            required: false,
                                            message: t('order.address_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<EnvironmentOutlined/>}
                                        placeholder={t('order.address_place')}

                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.phone_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<><PhoneOutlined/> &nbsp; +380</>}
                                        placeholder={t('order.phone_place')}

                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.name_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<UserOutlined/>}
                                        placeholder={t('order.name_place')}

                                    />
                                </Form.Item>


                            </div>
                            :
                            <div>

                                <Form.Item
                                    // label={t('order.city_place')}
                                    name="index"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.index_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<NumberOutlined/>}
                                        placeholder={t('order.index_place')}
                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.address2_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<EnvironmentOutlined/>}
                                        placeholder={t('order.address2_place')}

                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="phone"
                                    rules={[
                                        {
                                            required: false,
                                            message: t('order.phone_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<><PhoneOutlined/> &nbsp; +380</>}
                                        placeholder={t('order.phone_place')}

                                    />
                                </Form.Item>

                                <Form.Item
                                    // label="Password"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('order.name_mess'),
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<UserOutlined/>}
                                        placeholder={t('order.name_place')}

                                    />
                                </Form.Item>
                            </div>}
                    </fieldset>

                    <fieldset>
                        <CartPreview/>
                    </fieldset>

                    <fieldset>

                        <div className={c.block_header}>{t('order.choose_pay_way')}</div><br/>

                        <Form.Item>
                            <Radio.Group onChange={pay_way} defaultValue={payWay} >
                                <Radio.Button value="full">{t('order.pay_full')}</Radio.Button>
                                <Radio.Button value="part">{t('order.pay_part')}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        {payWay === 'full'
                            ?
                            <div>

                                {t('order.pay_full_descr')}
                            </div>
                            :
                            <div>
                                {t('order.pay_part_descr')}
                            </div>}

                        <span className={c.total}>
                            {t('order.now_you_pay') + payNow} UAH
                        </span>
                    </fieldset>




                    {/*<Form.Item name="rememberMe" valuePropName="checked" >*/}
                    {/*    <Checkbox>{t('auth.remember_me')}</Checkbox>*/}
                    {/*</Form.Item>*/}

                    {/*<Button  type="primary" onClick={handleSubmit}>Log in</Button>*/}


                    <Form.Item>
                        {/*<div className={c.error +' ' + (formGeneralError && c.error_show)}>Помилка заповнення форми!</div>*/}
                        <Error text='Помилка заповнення форми!' visible={tempInfoVisible}/><br/>
                        <Error text='Замовлених Вами товарів вже нема в наявності, перевірте замовлення!' visible={error_noMoreItems}/><br/>
                        {error_other && <><Error text={error_other} visible={error_other}/><br/></> }
                        <Button type="primary" htmlType="submit" loading={loadingButton} onClick={buttonClicked}
                        disabled={cart.cart.total_price < 100 ? 'disabled' : ''}
                        title={cart.cart.total_price < 100 ? 'Сума замовлення повинна бути 100 грн або більше' : ''}>
                            {t('order.go_to_pay')}
                        </Button>
                    </Form.Item>


                    {/*<Button  type="primary" loading>Log in</Button>*/}
                    {/*</div>*/}
                </Form>
            </Col>

        </Row>
            : <Preloader/>)

    // } else {
    //     // return <Redirect to={'/index'}/>
    // }


}

// secondary login form for TESTing
// const LoginForm2 = ({auth, handleSubmit, error}) => {
//
//     if (auth.isAuth === false) {
//
//         return <form onSubmit={handleSubmit}>
//
//             <div className={(!error ? c.noError : '') + ' ' + c.error}>
//                 {error}
//             </div>
//             {createMyField(null, Hidden, 'email2', [])}
//             {createMyField(null, Hidden, 'pass2', [])}
//
//             {/*  NO CAPTCHA NEEDED */}
//             {/*<div className={c.captchaDiv + ' ' + (!auth.capthaImg ? c.noCaptcha : '')}>*/}
//             {/*    <div>*/}
//             {/*        <img alt='captcha' src={auth.capthaImg ? auth.capthaImg : ''}/>*/}
//             {/*    </div>*/}
//             {/*    {createMyField('enter captcha', Input, 'captcha2', (!auth.capthaImg ? [] : [required, maxLen50, minLen2]))}*/}
//             {/*</div>*/}
//
//             {createMyField(null, Hidden, 'rememberMe2', [])}
//             <div>
//                 <button>Log in for test</button>
//             </div>
//         </form>
//     } else {
//         return <Redirect to={'/index'}/>
//     }
//
//
// }

const OrderReduxForm = reduxForm({
    form: 'order'
})(OrderForm)
// const LoginReduxForm2 = reduxForm({
//     form: 'auth2'
// })(LoginForm2)


const mapStateToProps = ({auth,cart}) => {
    //debugger
    return {
        auth: auth,
        cart: cart
        // initialValues: {
        //     email2: 'wovo4ka2010@gmail.com',
        //     pass2: '3AtGqkmDkKfmX6J',
        //     // email2: 'free@samuraijs.com',
        //     // pass2: 'free',
        //     rememberMe2: true,
        //
        // },

        //capthaImg: props.capthaImg
    }
};

export default compose(
    connect(mapStateToProps, {login, checkAuthorization,setCaptchaAnswer,getCart,addOrder}),
)(OrderContainer)

//
// export default () => {
//     return <>
//         <a href={`https://social-network.samuraijs.com/login`} target={`_blank`}>Потрібна авторизація</a>
//     </>
// }