import React, {useEffect, useState} from "react";
import c from "./AdminOrders.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import AdminListItem from "../AdminListItem/AdminListItem";
import {getCatList, getOrders, saveCatsOrder} from "../../redux/admin_reducer";
import AdminListCat from "../AdminListCat/AdminListCat";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import {Table, Tag} from "antd";
import {createMarkup, getColorByStatus, parseDate} from "../common/functions";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";


// const SortableItem = SortableElement(({ cat }) => (
//     <div className={c.my_item2}>
//         {/*<AdminPhotoView link={value} delPic={delPic}/>*/}
//
//         <AdminListCat cat={cat}/>
//     </div>
//
// ));
//
// const SortableList = SortableContainer(({ catList }) => {
//     return (
//         <div>
//
//             {catList && catList.map((cat,index)=>{
//                 return <SortableItem  key={`item-${index}`} index={index}  cat={cat}  />;
//             })}
//
//
//             {/*{items.map((value, index) => (*/}
//             {/*    value !== '' && <SortableItem key={`item-${value}`} index={index} value={value} delPic={delPic} />*/}
//             {/*))}*/}
//         </div>
//     );
// });

const AdminOrders = (props) => {

    const { t } = useTranslation();
    useEffect(()=>{
        if(props.orders === null){
            props.getOrders()
        }

    },[])

    // const [tempArrItems,setTAI] = useState(false);
    // const [tempArrItemsChanged,setTAIChanged] = useState(false);
    //
    //
    // const onSortEnd = ({ oldIndex, newIndex }) => {
    //     // console.log(oldIndex, newIndex)
    //     // console.log(tempArrItems)
    //     const newArr = arrayMove(tempArrItems, oldIndex, newIndex);
    //     setTAI(newArr)
    //
    //     // console.log(tempArrItems)
    // };
    //
    // const saveOrder = () => {
    //     console.log('tempArrItems')
    //     console.log(tempArrItems)
    //
    //     let out = []
    //     tempArrItems.map((cat) => {
    //         out.push(cat.id)
    //     })
    //
    //     out = {
    //         'cats': out.join()
    //     }
    //
    //     // console.log()
    //     props.saveCatsOrder(out)
    // }
    //
    //
    // // debugger
    // useEffect(()=>{
    //     props.getCatList()
    // },[]) // [] == componentDidMount
    //
    // if(props.catList !== null){
    //     if(!tempArrItemsChanged){
    //         setTAI(props.catList);
    //         setTAIChanged(true)
    //     }
    // }

    let dataSource = Array();
    if(props.orders !== null){
        props.orders.forEach((o)=>{
            dataSource.push({
                id: o.id,
                user: o.user_id,
                update_date: o.update_date,
                amount: o.total_amount + ' ' + t('pay.valuta'),
                status: o.status,
            })
        })
    }

    useEffect(()=>{
        dataSource = Array()
        if(props.orders !== null){
            props.orders.forEach((o)=>{
                dataSource.push({
                    id: o.id,
                    user: o.user_id,
                    update_date: o.update_date,
                    amount: o.total_amount + ' ' + t('pay.valuta'),
                    status: o.status,
                })
            })
        }
    },[props.orders !== null ? props.orders : null])




    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Юзер',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Дата оновлення',
            dataIndex: 'update_date',
            key: 'update_date',
            render: date => (
                <>
                    {parseDate(date)}
                </>
            ),
            defaultSortOrder: 'descend',
            sorter: (a, b) => parseInt(a.update_date) - parseInt(b.update_date),
        },
        {
            title: 'Сума',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,
            defaultSortOrder: 'none',
            sorter: (a, b) => parseInt(a.amount) - parseInt(b.amount),
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <>
                    {<Tag color={getColorByStatus(status)} key={status}>
                        <div dangerouslySetInnerHTML={createMarkup(t('order_status.'+status).toUpperCase())}></div>
                    </Tag>

                    }
                </>
            ),
        },
        {
            title: 'Дії',
            key: 'actions',
            render: (text, record) => (
                <NavLink to={'/adminko/admin_view_order/'+record.id}>{t('myOrders.view_order')}</NavLink>
            ),
        },
    ];

    return (
        <div className={c.main_body}>
            <h2>Список замовлень</h2>

            <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 25, hideOnSinglePage:true }} />

        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        orders: state.admin.orders
    }
}

export default compose(
    connect(mapStateToProps,{getCatList,saveCatsOrder,getOrders})
    // connect(mapStateToProps,null)
)(AdminOrders);