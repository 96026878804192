import {API} from "../api/api";
import {setNoInet} from "./app_reducer";
// import {setItemList} from "./admin_reducer";
// import {setCatList} from "./admin_reducer";

let initialState = {
    cart: null,
    cartOpened: false,
    cartItemsFull: null
}

const SET_CART = 'sweetkids/cart/SET_CART';
const SET_CART_ITEMS_FULL = 'sweetkids/cart/SET_CART_ITEMS_FULL';
const SET_CART_OPENED = 'sweetkids/cart/SET_CART_OPENED';
const SET_CART_TOTAL = 'sweetkids/cart/SET_CART_TOTAL';
// const SET_ITEM_LIST = 'sweetkids/index/SET_ITEM_LIST';

const cart_reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CART:
            // debugger
            return {
                ...state,
                cart: action.cart,
            }
        case SET_CART_ITEMS_FULL:
            // debugger
            return {
                ...state,
                cartItemsFull: action.cartItemsFull,
            }
        case SET_CART_OPENED:
            // debugger
            return {
                ...state,
                cartOpened: action.cartOpened,
            }
        case SET_CART_TOTAL:
            // debugger
            return {
                ...state,
                cart:{
                    total_price: action.cartTotal
                    }
            }
        // case SET_ITEM_LIST:
        //     // debugger
        //     return {
        //         ...state,
        //         itemList: action.items,
        //     }
/*
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload,
            }
        /!*case LOG_OUT:
            return {
                ...state,
                isAuth: false
            }*!/

        case SET_USER_IMG:
            return {
                ...state,
                userImg: action.img
            }

        case SET_CAPTCHA_IMG:
            return {
                ...state,
                capthaImg: action.img
            }
        case SET_CAPTCHA_ANS:
            return {
                ...state,
                captchaAnswer: action.ans
            }*/
        /*        case SET_ERROR:
                    return {
                        ...state,
                        loginError:action.err
                    }*/


        default:
            return state;

    }

}

export default cart_reducer;



export const setCart = (cart) => {
    return {type: SET_CART, cart: cart};
}
export const setCartItemsFull = (cartItemsFull) => {
    return {type: SET_CART_ITEMS_FULL, cartItemsFull: cartItemsFull};
}
export const setCartOpened = (cartOpened) => {
    return {type: SET_CART_OPENED, cartOpened: cartOpened};
}
export const setCartTotal = (cartTotal) => { // unUsed
    return {type: SET_CART_TOTAL, cartTotal: cartTotal};
}

// export const setItemListMain = (items) => {
//     return {type: SET_ITEM_LIST, items: items};
// }


export const getCart = () => async (dispatch) => {
    const result = await API.getCart();
    // console.log('getCart');
    // console.log(result.data);
    if(result !== undefined){
        if(result.data.error === 0){
            if(result.data.main_data.message === 'no cart' || result.data.main_data.message === 'could not get cart'){
                dispatch(setCart('empty'));
            }else{
                dispatch(setCart(result.data.main_data));
                dispatch(getCartItems());
                // console.log(result2)
                // dispatch(setCartItemsFull(result2.data.main_data));
            }
        }else{
            // error
        }
    }


}
export const getCartItems = () => async (dispatch) => {
    try{

        const result = await API.getCartItems();
        // console.log('getCartItems');
        // console.log(result.data);

        dispatch(setCartItemsFull(result.data.main_data.items));
        // if(result.data.error === 0){
        //     if(result.data.main_data.message === 'no cart' || result.data.main_data.message === 'could not get cart'){
        //         dispatch(setCart(null));
        //     }else{
        //         dispatch(setCart(result.data.main_data));
        //     }
        // }else{
        //     // error
        // }
    }
    catch(error){
        // console.log(error)
        dispatch(setNoInet(true))
        // console.log('NoInet')
    }

}

export const updCart = (newCartData) => async (dispatch) => {
    dispatch(setCart(newCartData));
    const result = await API.updateCart(newCartData);
    // const result2 = await API.getCart();
    // console.log('getCatListMain');
    // console.log(result);
}
export const newCart = () => async (dispatch) => {
    // dispatch(setCart(newCartData));
    const result = await API.createCart();
    // const result2 = await API.getCart();
    // console.log('getCatListMain');
    // console.log(result);
}


// export const getItemListMain = (itemsPerPage,currentPage,cats,sorting) => async (dispatch) => {
//     const result = await API.getFilteredItems(itemsPerPage,currentPage,cats,sorting);
//     // console.log('getItemListMain');
//     // console.log(result);
//     dispatch(setItemListMain(result.items));
// }

// export const getItems = () => async (dispatch)  => {
//     const result = await API.getFilteredItems()
//     console.log('getItems');
//     console.log(result);
// }